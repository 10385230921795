import { EventId, FacilityId, HorseId, NewsEntryId, NotificationId, NotificationType, RiderTaskId, SurveyId, UserId } from '@/types';

export interface PushNotification {
  type: NotificationType;
  notificationId: NotificationId;
}

export interface NewsEntryPublishedPushNotification extends PushNotification {
  type: NotificationType.NEWS_ENTRY_PUBLISHED;
  newsId: NewsEntryId;
}
export function isNewsEntryPublishedPushNotification(
  notification: PushNotification
): notification is NewsEntryPublishedPushNotification {
  return notification.type === NotificationType.NEWS_ENTRY_PUBLISHED;
}

export interface EventPublishedPushNotification extends PushNotification {
  type: NotificationType.EVENT_PUBLISHED;
  eventId: EventId;
}
export function isEventPublishedPushNotification(
  notification: PushNotification
): notification is EventPublishedPushNotification {
  return notification.type === NotificationType.EVENT_PUBLISHED;
}

export interface EventCanceledPushNotification extends PushNotification {
  type: NotificationType.EVENT_CANCELED;
  eventId: EventId;
}
export function isEventCanceledPushNotification(
  notification: PushNotification
): notification is EventCanceledPushNotification {
  return notification.type === NotificationType.EVENT_CANCELED;
}

export interface TaskCreatedPushNotification extends PushNotification {
  type: NotificationType.RIDER_TASK_CREATED;
  taskId: RiderTaskId;
}
export function isTaskCreatedPushNotification(
  notification: PushNotification
): notification is TaskCreatedPushNotification {
  return notification.type === NotificationType.RIDER_TASK_CREATED;
}

export interface TaskAssignedPushNotification extends PushNotification {
  type: NotificationType.RIDER_TASK_ASSIGNED;
  taskId: RiderTaskId;
}
export function isTaskAssignedPushNotification(
  notification: PushNotification
): notification is TaskAssignedPushNotification {
  return notification.type === NotificationType.RIDER_TASK_ASSIGNED;
}

export interface TaskAssignedWithExecutionDateUpdatePushNotification extends PushNotification {
  type: NotificationType.RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE;
  taskId: RiderTaskId;
}
export function isTaskAssignedWithExecutionDateUpdatePushNotification(
  notification: PushNotification
): notification is TaskAssignedWithExecutionDateUpdatePushNotification {
  return notification.type === NotificationType.RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE;
}

export interface TaskAssignedToYouPushNotification extends PushNotification {
  type: NotificationType.RIDER_TASK_ASSIGNED_TO_YOU;
  taskId: RiderTaskId;
}
export function isTaskAssignedToYouPushNotification(
  notification: PushNotification
): notification is TaskAssignedToYouPushNotification {
  return notification.type === NotificationType.RIDER_TASK_ASSIGNED_TO_YOU;
}

export interface isTaskRejectedPushNotification extends PushNotification {
  type: NotificationType.RIDER_TASK_ASSIGNED_TO_YOU;
  taskId: RiderTaskId;
}
export function isTaskRejectedPushNotification(
  notification: PushNotification
): notification is isTaskRejectedPushNotification {
  return notification.type === NotificationType.RIDER_TASK_REJECTED;
}

export interface TaskCompletedPushNotification extends PushNotification {
  type: NotificationType.RIDER_TASK_COMPLETED;
  taskId: RiderTaskId;
}
export function isTaskCompletedPushNotification(
  notification: PushNotification
): notification is TaskCompletedPushNotification {
  return notification.type === NotificationType.RIDER_TASK_COMPLETED;
}

export interface TaskWithdrawnPushNotification extends PushNotification {
  type: NotificationType.RIDER_TASK_WITHDRAWN;
  taskId: RiderTaskId;
}
export function isTaskWithdrawnPushNotification(
  notification: PushNotification
): notification is TaskWithdrawnPushNotification {
  return notification.type === NotificationType.RIDER_TASK_WITHDRAWN;
}

export interface TaskCreatedAssignedPushNotification extends PushNotification {
  type: NotificationType.RIDER_TASK_CREATED_ASSIGNED;
  taskId: RiderTaskId;
}
export function isTaskCreatedAssignedPushNotification(
  notification: PushNotification
): notification is TaskCreatedAssignedPushNotification {
  return notification.type === NotificationType.RIDER_TASK_CREATED_ASSIGNED;
}

export interface FeedProtocolMissingSignalPushNotification extends PushNotification {
  type: NotificationType.FEED_PROTOCOL_MISSING_SIGNAL;
}
export function isFeedProtocolMissingSignalPushNotification(
  notification: PushNotification
): notification is FeedProtocolMissingSignalPushNotification {
  return notification.type === NotificationType.FEED_PROTOCOL_MISSING_SIGNAL;
}

export interface ImpersonationRequestedPushNotification extends PushNotification {
  type: NotificationType.IMPERSONATION_REQUESTED;
  token: string;
}
export function isImpersonationRequestedPushNotification(
  notification: PushNotification
): notification is ImpersonationRequestedPushNotification {
  return notification.type === NotificationType.IMPERSONATION_REQUESTED;
}

export interface ImpersonationConfirmedPushNotification extends PushNotification {
  type: NotificationType.IMPERSONATION_CONFIRMED;
  userId: UserId;
}
export function isImpersonationConfirmedPushNotification(
  notification: PushNotification
): notification is ImpersonationConfirmedPushNotification {
  return notification.type === NotificationType.IMPERSONATION_CONFIRMED;
}

export interface VaccinationExpiresSoonPushNotification extends PushNotification {
  type: NotificationType.VACCINATION_EXPIRES_SOON;
  horseId: HorseId;
}
export function isVaccinationExpiresSoonPushNotification(
  notification: PushNotification
): notification is VaccinationExpiresSoonPushNotification {
  return notification.type === NotificationType.VACCINATION_EXPIRES_SOON;
}

export interface FacilityReservationCanceledDueToFacilityBlockerPushNotification extends PushNotification {
  type: NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_FACILITY_BLOCKER;
  facilityId: FacilityId;
}
export function isFacilityReservationCanceledDueToFacilityBlockerPushNotification(
  notification: PushNotification
): notification is FacilityReservationCanceledDueToFacilityBlockerPushNotification {
  return notification.type === NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_FACILITY_BLOCKER;
}

export interface FacilityReservationCanceledDueToReducedOpeningHoursPushNotification extends PushNotification {
  type: NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_REDUCED_OPENING_HOURS;
  facilityId: FacilityId;
}
export function isFacilityReservationCanceledDueToReducedOpeningHoursPushNotification(
  notification: PushNotification
): notification is FacilityReservationCanceledDueToReducedOpeningHoursPushNotification {
  return notification.type === NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_REDUCED_OPENING_HOURS;
}

export interface FacilityReservationCanceledDueToDisabledActivityPushNotification extends PushNotification {
  type: NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_DISABLED_ACTIVITY;
  facilityId: FacilityId;
}
export function isFacilityReservationCanceledDueToDisabledActivityPushNotification(
  notification: PushNotification
): notification is FacilityReservationCanceledDueToDisabledActivityPushNotification {
  return notification.type === NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_DISABLED_ACTIVITY;
}

export interface FacilityReservationCanceledDueToRidingLessonPushNotification extends PushNotification {
  type: NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_RIDING_LESSON;
  facilityId: FacilityId;
}
export function isFacilityReservationCanceledDueToRidingLessonPushNotification(
  notification: PushNotification
): notification is FacilityReservationCanceledDueToRidingLessonPushNotification {
  return notification.type === NotificationType.FACILITY_RESERVATION_CANCELED_DUE_TO_RIDING_LESSON;
}

export interface RidingLessonRescheduledPushNotification extends PushNotification {
  type: NotificationType.RIDING_LESSON_RESCHEDULED;
  facilityId: FacilityId;
}
export function isRidingLessonRescheduledPushNotification(
  notification: PushNotification
): notification is RidingLessonRescheduledPushNotification {
  return notification.type === NotificationType.RIDING_LESSON_RESCHEDULED;
}

export interface RidingLessonTypeUpdatedPushNotification extends PushNotification {
  type: NotificationType.RIDING_LESSON_TYPE_UPDATED;
  facilityId: FacilityId;
}
export function isRidingLessonTypeUpdatedPushNotification(
  notification: PushNotification
): notification is RidingLessonTypeUpdatedPushNotification {
  return notification.type === NotificationType.RIDING_LESSON_TYPE_UPDATED;
}

export interface InvitedAsRideSharePushNotification extends PushNotification {
  type: NotificationType.INVITED_AS_RIDE_SHARE;
  facilityId: FacilityId;
}
export function isInvitedAsRideSharePushNotification(
  notification: PushNotification
): notification is InvitedAsRideSharePushNotification {
  return notification.type === NotificationType.INVITED_AS_RIDE_SHARE;
}

export interface LaborServiceEntryCreatedPushNotification extends PushNotification {
  type: NotificationType.LABOR_SERVICE_ENTRY_CREATED;
  facilityId: FacilityId;
}
export function isLaborServiceEntryCreatedPushNotification(
  notification: PushNotification
): notification is LaborServiceEntryCreatedPushNotification {
  return notification.type === NotificationType.LABOR_SERVICE_ENTRY_CREATED;
}

export interface LaborServiceEntryApprovedPushNotification extends PushNotification {
  type: NotificationType.LABOR_SERVICE_ENTRY_APPROVED;
  facilityId: FacilityId;
}
export function isLaborServiceEntryApprovedPushNotification(
  notification: PushNotification
): notification is LaborServiceEntryApprovedPushNotification {
  return notification.type === NotificationType.LABOR_SERVICE_ENTRY_APPROVED;
}

export interface LaborServiceEntryRejectedPushNotification extends PushNotification {
  type: NotificationType.LABOR_SERVICE_ENTRY_REJECTED;
  facilityId: FacilityId;
}
export function isLaborServiceEntryRejectedPushNotification(
  notification: PushNotification
): notification is LaborServiceEntryRejectedPushNotification {
  return notification.type === NotificationType.LABOR_SERVICE_ENTRY_REJECTED;
}

export interface UserDeletedOwnAccountPushNotification extends PushNotification {
  type: NotificationType.USER_DELETED_OWN_ACCOUNT;
  facilityId: FacilityId;
}
export function isUserDeletedOwnAccountPushNotification(
  notification: PushNotification
): notification is UserDeletedOwnAccountPushNotification {
  return notification.type === NotificationType.USER_DELETED_OWN_ACCOUNT;
}

export interface SurveyCreatedPushNotification extends PushNotification {
  type: NotificationType.SURVEY_CREATED;
  surveyId: SurveyId;
}
export function isSurveyCreatedPushNotification(
  notification: PushNotification
): notification is SurveyCreatedPushNotification {
  return notification.type === NotificationType.SURVEY_CREATED;
}

export interface SurveyAnswerDeletedPushNotification extends PushNotification {
  type: NotificationType.SURVEY_ANSWER_DELETED;
  surveyId: SurveyId;
}
export function isSurveyAnswerDeletedPushNotification(
  notification: PushNotification
): notification is SurveyAnswerDeletedPushNotification {
  return notification.type === NotificationType.SURVEY_ANSWER_DELETED;
}
