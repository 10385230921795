import { render, staticRenderFns } from "./register-for-riding-lesson-dialog.vue?vue&type=template&id=5f50c911&scoped=true&"
import script from "./register-for-riding-lesson-dialog.vue?vue&type=script&lang=ts&"
export * from "./register-for-riding-lesson-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./register-for-riding-lesson-dialog.vue?vue&type=style&index=0&id=5f50c911&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f50c911",
  null
  
)

export default component.exports