import { render, staticRenderFns } from "./paddock-plan.vue?vue&type=template&id=4af407d2&scoped=true&"
import script from "./paddock-plan.vue?vue&type=script&lang=ts&"
export * from "./paddock-plan.vue?vue&type=script&lang=ts&"
import style0 from "./paddock-plan.vue?vue&type=style&index=0&id=4af407d2&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af407d2",
  null
  
)

export default component.exports