
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { constructForm, csvCompliantRule, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { FarmServiceName, LanguageWithoutGerman } from '@/types';

interface Controls extends FormControls {
  language: FormControl<LanguageWithoutGerman>;
  name: FormControl<FarmServiceName>;
}

@Component
export default class UpdateFarmServiceNameTranslationDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.smallNested;

  @Prop({ type: String, required: true })
  readonly language!: LanguageWithoutGerman;

  @Prop({ type: String, required: true })
  readonly name!: FarmServiceName;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        language: {
          label: 'Sprache',
          value: this.language,
          isRequired: true,
          rules: [],
        },
        name: {
          label: 'Name',
          value: this.name,
          isRequired: true,
          rules: [
            maxLengthRule(255),
            csvCompliantRule(),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const updatedTranslation: { language: LanguageWithoutGerman, name: FarmServiceName } = {
      language: formValues.language!,
      name: formValues.name!,
    };

    this.$emit('submitted', updatedTranslation);

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
    this.$emit('closed');
  }

}
