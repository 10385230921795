
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { PersonId, PersonIdList } from '@/types';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';

export interface Person {
  personId: PersonId;
  fullName: string;
  isConnectedToUser: boolean;
}

function validateItems(items: unknown[]): boolean {
  return items.every((item: unknown) => !!item
      && typeof item === 'object'
      && Object.hasOwn(item, 'personId')
      && Object.hasOwn(item, 'fullName')
      && Object.hasOwn(item, 'isConnectedToUser'));
}

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class PersonMultiAutocompleteFormControl extends Vue implements FormControlComponent<PersonIdList> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<PersonIdList>;

  @Prop({ type: Array, required: true, validator: validateItems })
  readonly items!: Person[];

  @Prop({ type: Boolean, default: false })
  readonly isSelectAllVisible!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: PersonIdList = [];

  formFieldValueWatcher = emptyFormFieldWatcher();

  get areAllPersonsSelected(): boolean {
    return this.internalValue.length > 0
      && this.items.length === this.internalValue.length;
  }

  get areSomePersonsSelected(): boolean {
    return this.internalValue.length > 0
      && this.internalValue.length !== this.items.length;
  }

  mounted(): void {
    mountFormControl(this);

    watch(() => this.items, () => this.updateAllowedInternalValues(), { immediate: true });
  }

  updateAllowedInternalValues(): void {
    const countOfInternalValuesBeforeRemoval = this.internalValue.length;
    this.internalValue = this.items
      .map((item) => item.personId)
      .filter((currentPersonId) => this.internalValue.some((personId) => currentPersonId === personId));

    if (countOfInternalValuesBeforeRemoval !== this.internalValue.length) {
      internalValuesChanged(this);
    }
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  selectionChanged(): void {
    internalValuesChanged(this);
  }

  toggleSelectAll(): void {
    this.internalValue = this.areAllPersonsSelected
      ? []
      : this.items.map((item) => item.personId);

    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  customCheckboxClicked(personId: PersonId): void {
    if (this.internalValue.includes(personId)) {
      this.internalValue.splice(this.internalValue.indexOf(personId), 1);
    } else {
      this.internalValue.push(personId);
    }

    internalValuesChanged(this);
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value ?? [];
  }

  formValueFromInternalValues(): FormControlValue<PersonIdList> {
    return this.internalValue.length > 0
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
