
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { FarmServiceNameTranslations, FarmServiceName, LanguageWithoutGerman, languagesWithoutGerman } from '@/types';
import { languageTranslation } from '@/helpers/translations';
import CreateFarmServiceNameTranslation from './create-farm-service-name-translation-dialog.vue';
import UpdateFarmServiceNameTranslation from './update-farm-service-name-translation-dialog.vue';

@Component({
  components: {
    CreateFarmServiceNameTranslation,
    UpdateFarmServiceNameTranslation,
  },
  methods: { labelWithRequiredIndicator, isFieldShownAsContainingAnError },
})
export default class FarmServiceNameTranslationsFormControl extends Vue implements FormControlComponent<FarmServiceNameTranslations> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<FarmServiceNameTranslations>;

  @Prop({ type: String, default: null })
  readonly infoText!: string | null;

  readonly formControlId = createFormControlId();

  readonly languageTranslation = languageTranslation;

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: FarmServiceNameTranslations = {};

  formFieldValueWatcher = emptyFormFieldWatcher();

  isCreateDisabled = true;

  mounted(): void {
    this.updateCreateDisabled();

    mountFormControl(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  createTranslation(item: { language: LanguageWithoutGerman, name: FarmServiceName }): void {
    this.internalValue[item.language] = item.name;

    this.updateCreateDisabled();

    this.blurred();

    internalValuesChanged(this);
  }

  updateTranslation(item: { language: LanguageWithoutGerman, name: FarmServiceName }): void {
    this.internalValue[item.language] = item.name;

    this.blurred();

    internalValuesChanged(this);
  }

  deleteTranslation(language: LanguageWithoutGerman): void {
    delete this.internalValue[language];

    this.updateCreateDisabled();

    this.blurred();

    internalValuesChanged(this);
  }

  updateCreateDisabled(): void {
    this.isCreateDisabled = Object.values(this.internalValue).length >= languagesWithoutGerman.length;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? {}
      : this.formControl.value;

    this.updateCreateDisabled();
  }

  formValueFromInternalValues(): FormControlValue<FarmServiceNameTranslations> {
    return Object.values(this.internalValue).length > 0
      ? this.internalValue
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
