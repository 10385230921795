import { render, staticRenderFns } from "./feeding-plan-table.vue?vue&type=template&id=d54d108c&"
import script from "./feeding-plan-table.vue?vue&type=script&lang=ts&"
export * from "./feeding-plan-table.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports