import Vue from 'vue';
// -- Form helpers
import FormControlMessages from '@/components/form/form-control-messages.vue';
import Form from '@/components/form/form.vue';
// -- Form controls (alphabetically)
import AddressFormControl from '@/components/form/address-form-control.vue';
import ArchivedStatusFormControl from '@/components/form/archived-status-form-control.vue';
import CalendarViewPreferenceFormControl from '@/components/form/calendar-view-preference-form-control.vue';
import CheckboxFormControl from '@/components/form/checkbox-form-control.vue';
import ColorFormControl from '@/components/form/color-form-control.vue';
import CountryFormControl from '@/components/form/country-form-control.vue';
import CustomFieldSelectFormControl from '@/components/form/custom-field-select-form-control.vue';
import CustomFieldTypeFormControl from '@/components/form/custom-field-type-form-control.vue';
import CustomFieldsFormControl from '@/components/form/custom-fields-form-control.vue';
import CustomFieldsWithoutTextAndLongtextFormControl from '@/components/form/custom-fields-without-text-and-longtext-form-control.vue';
import CurrencyFormControl from '@/components/form/currency-form-control.vue';
import DateMultiCheckboxesFormControl from '@/components/form/date-multi-checkboxes-form-control.vue';
import MomentDatePickerFormControl from '@/components/form/moment-date-picker-form-control.vue';
import DatePickerFormControl from '@/components/form/date-picker-form-control.vue';
import DateSelectFormControl from '@/components/form/date-select-form-control.vue';
import DateTimePickerFormControl from '@/components/form/date-time-picker-form-control.vue';
import EmailAddressFormControl from '@/components/form/email-address-form-control.vue';
import FacilityAutocompleteFormControl from '@/components/form/facility-autocomplete-form-control.vue';
import FacilityReservationActivityMultiFormControl from '@/components/form/facility-reservation-activity-multi-form-control.vue';
import FacilityReservationActivityFormControl from '@/components/form/facility-reservation-activity-form-control.vue';
import FarmAutocompleteFormControl from '@/components/form/farm-autocomplete-form-control.vue';
import FarmManagerPermissionsFormControl from '@/components/form/farm-manager-permissions-form-control.vue';
import FarmServiceBookingTypeFormControl from '@/components/form/farm-service-booking-type-form-control.vue';
import FarmServiceFormControl from '@/components/form/farm-service-autocomplete-form-control.vue';
import FarmServiceMultiAutocompleteFormControl from '@/components/form/farm-service-multi-autocomplete-form-control.vue';
import FarmServiceMultiCheckboxFormControl from '@/components/form/farm-service-multi-checkbox-form-control.vue';
import FarmServiceNameTranslationsFormControl from '@/components/form/farm-service-name-translations-form-control.vue';
import FarmServiceUnitFormControl from '@/components/form/farm-service-unit-form-control.vue';
import FarmTaskRepetitionFormControl from '@/components/form/farm-task-repetition-form-control.vue';
import FeaturesAutocompleteFormControl from '@/components/form/features-autocomplete-form-control.vue';
import FeaturesFormControl from '@/components/form/features-form-control.vue';
import FeedUnitFormControl from '@/components/form/feed-unit-form-control.vue';
import FileFormControl from '@/components/form/file-form-control.vue';
import HayTypeSelectFormControl from '@/components/form/hay-type-select-form-control.vue';
import HorseAutocompleteFormControl from '@/components/form/horse-autocomplete-form-control.vue';
import HorseMultiSelectFormControl from '@/components/form/horse-multi-select-form-control.vue';
import HorseSelectFormControl from '@/components/form/horse-select-form-control.vue';
import HtmlFormControl from '@/components/form/html-form-control.vue';
import IllnessFormControl from '@/components/form/illness-form-control.vue';
import IntervalFormControl from '@/components/form/interval-form-control.vue';
import IntervalsFormControl from '@/components/form/intervals-form-control.vue';
import ImageCopperFormControl from '@/components/form/image-cropper-form-control.vue';
import LanguageFormControl from '@/components/form/language-form-control.vue';
import LinkFormControl from '@/components/form/link-form-control.vue';
import LocaleFormControl from '@/components/form/locale-form-control.vue';
import MonthFormControl from '@/components/form/month-form-control.vue';
import MoveHorseToFormControl from '@/components/form/move-horse-to-form-control.vue';
import MultiTextFormControl from '@/components/form/multi-text-form-control.vue';
import NameFormControl from '@/components/form/name-form-control.vue';
import NameOrderTypeFormControl from '@/components/form/name-order-type-form-control.vue';
import NumberFormControl from '@/components/form/number-form-control.vue';
import NumberAutocompleteFormControl from '@/components/form/number-autocomplete-form-control.vue';
import NumberRangeIntervalFormControl from '@/components/form/number-range-interval-form-control.vue';
import NumberSelectionFormControl from '@/components/form/number-selection-form-control.vue';
import OptionFormControl from '@/components/form/option-form-control.vue';
import OptionsFormControl from '@/components/form/options-form-control.vue';
import OrganizationAutocompleteFormControl from '@/components/form/organization-autocomplete-form-control.vue';
import PasswordFormControl from '@/components/form/password-form-control.vue';
import PaymentMethodFormControl from '@/components/form/payment-method-form-control.vue';
import PersonaFormControl from '@/components/form/persona-form-control.vue';
import PersonAutocompleteFormControl from '@/components/form/person-autocomplete-form-control.vue';
import PersonMultiAutocompleteFormControl from '@/components/form/person-multi-autocomplete-form-control.vue';
import PhoneNumberFormControl from '@/components/form/phone-number-form-control.vue';
import StrainFormControl from '@/components/form/strain-form-control.vue';
import SubscriptionScheduleFormControl from '@/components/form/subscription-schedule-form-control.vue';
import TextareaFormControl from '@/components/form/textarea-form-control.vue';
import TextFormControl from '@/components/form/text-form-control.vue';
import TimeConfigurationTypeFormControl from '@/components/form/time-configuration-type-form-control.vue';
import TimeFormControl from '@/components/form/time-form-control.vue';
import TimeFrameFormControl from '@/components/form/time-frame-form-control.vue';
import TimeFrameIntervalFormControl from '@/components/form/time-frame-interval-form-control.vue';
import TimeFrameSelectFormControl from '@/components/form/time-frame-select-form-control.vue';
import TimeOptionIntervalFormControl from '@/components/form/time-option-interval-form-control.vue';
import TimeOptionIntervalSelectionFormControl from '@/components/form/time-option-interval-selection-form-control.vue';
import RegistrationCodeFormControl from '@/components/form/registration-code-form-control.vue';
import ReservationDisplayNameTypeFormControl from '@/components/form/reservation-display-name-type-form-control.vue';
import RidingLessonAutocompleteFormControl from '@/components/form/riding-lesson-autocomplete-form-control.vue';
import RidingLessonDurationFormControl from '@/components/form/riding-lesson-duration-form-control.vue';
import RidingLessonMultiCheckboxFormControl from '@/components/form/riding-lesson-multi-checkbox-form-control.vue';
import RidingLessonRegistrationTypeRadioFormControl from '@/components/form/riding-lesson-registration-type-radio-form-control.vue';
import RidingLessonTypeAutocompleteFormControl from '@/components/form/riding-lesson-type-autocomplete-form-control.vue';
import SalutationFormControl from '@/components/form/salutation-form-control.vue';
import SurveyAnswerPerFormControl from '@/components/form/survey-answer-per-form-control.vue';
import UserAutocompleteFormControl from '@/components/form/user-autocomplete-form-control.vue';
import UserGroupAutocompleteFormControl from '@/components/form/user-group-autocomplete-form-control.vue';
import UserGroupMultiFormControl from '@/components/form/user-group-multi-form-control.vue';
import UserMultiCheckboxFormControl from '@/components/form/user-multi-checkbox-form-control.vue';
import UserMultiSelectFormControl from '@/components/form/user-multi-select-form-control.vue';
import UserRoleFormControl from '@/components/form/user-role-form-control.vue';
import VaccinationWaitPeriodFormControl from '@/components/form/vaccination-wait-period-form-control.vue';
import VaccineFormControl from '@/components/form/vaccine-form-control.vue';
import WeekdayFormControl from '@/components/form/weekday-form-control.vue';
import WeekdaysFormControl from '@/components/form/weekdays-form-control.vue';
import YesNoSelectFormControl from '@/components/form/yes-no-select-form-control.vue';
// -- Domain form controls
import FeedingSettingsFormControl from '@/private/rider/my-stable/components/feeding-settings-form-control.vue';
import GerblhofConcentratedFeedingAmountFormControl from '@/private/rider/my-stable/components/gerblhof-concentrated-feeding-amount-form-control.vue';
import GerblhofConcentratedFeedingsFormControl from '@/private/rider/my-stable/components/gerblhof-concentrated-feedings-form-control.vue';
import GerblhofConcentratedFeedingTypeSelectFormControl from '@/private/rider/my-stable/components/gerblhof-concentrated-feeding-type-select-form-control.vue';
import RidingLessonRegistrationRadioFormControl from '@/private/rider/booking-calendars/components/riding-lesson-registration-radio-form-control.vue';
import SurveyAnswersForHorsesFormControl from '@/private/rider/pinboard/components/survey-answers-for-horses-form-control.vue';
import SurveyAnswersForHorsesMultipleFormControl from '@/private/rider/pinboard/components/survey-answers-for-horses-multiple-form-control.vue';
import SurveyAnswersForUserFormControl from '@/private/rider/pinboard/components/survey-answers-for-user-form-control.vue';
import SurveyAnswersForUserMultipleFormControl from '@/private/rider/pinboard/components/survey-answers-for-user-multiple-form-control.vue';

// -- Form helpers
Vue.component('a-form-control-messages', FormControlMessages);
Vue.component('a-form', Form);

// -- Form controls (alphabetically)
Vue.component('a-archived-status-form-control', ArchivedStatusFormControl);
Vue.component('a-calendar-view-preference-form-control', CalendarViewPreferenceFormControl);
Vue.component('a-checkbox-form-control', CheckboxFormControl);
Vue.component('a-color-form-control', ColorFormControl);
Vue.component('a-country-form-control', CountryFormControl);
Vue.component('a-custom-field-select-form-control', CustomFieldSelectFormControl);
Vue.component('a-custom-field-type-form-control', CustomFieldTypeFormControl);
Vue.component('a-custom-fields-form-control', CustomFieldsFormControl);
Vue.component('a-custom-fields-without-text-and-longtext-form-control', CustomFieldsWithoutTextAndLongtextFormControl);
Vue.component('a-currency-form-control', CurrencyFormControl);
Vue.component('a-date-multi-checkboxes-form-control', DateMultiCheckboxesFormControl);
Vue.component('a-date-picker-form-control', DatePickerFormControl);
Vue.component('a-date-select-form-control', DateSelectFormControl);
Vue.component('a-date-time-picker-form-control', DateTimePickerFormControl);
Vue.component('a-email-address-form-control', EmailAddressFormControl);
Vue.component('a-facility-autocomplete-form-control', FacilityAutocompleteFormControl);
Vue.component('a-facility-reservation-activity-multi-form-control', FacilityReservationActivityMultiFormControl);
Vue.component('a-facility-reservation-activity-form-control', FacilityReservationActivityFormControl);
Vue.component('a-farm-autocomplete-form-control', FarmAutocompleteFormControl);
Vue.component('a-farm-manager-permissions-form-control', FarmManagerPermissionsFormControl);
Vue.component('a-farm-service-booking-type-form-control', FarmServiceBookingTypeFormControl);
Vue.component('a-farm-service-form-control', FarmServiceFormControl);
Vue.component('a-farm-service-multi-autocomplete-form-control', FarmServiceMultiAutocompleteFormControl);
Vue.component('a-farm-service-multi-checkbox-form-control', FarmServiceMultiCheckboxFormControl);
Vue.component('a-farm-service-name-translations-form-control', FarmServiceNameTranslationsFormControl);
Vue.component('a-farm-service-unit-form-control', FarmServiceUnitFormControl);
Vue.component('a-farm-task-repetition-form-control', FarmTaskRepetitionFormControl);
Vue.component('a-features-autocomplete-form-control', FeaturesAutocompleteFormControl);
Vue.component('a-features-form-control', FeaturesFormControl);
Vue.component('a-feed-unit-form-control', FeedUnitFormControl);
Vue.component('a-file-form-control', FileFormControl);
Vue.component('a-hay-type-form-control', HayTypeSelectFormControl);
Vue.component('a-horse-autocomplete-form-control', HorseAutocompleteFormControl);
Vue.component('a-horse-multi-select-form-control', HorseMultiSelectFormControl);
Vue.component('a-horse-select-form-control', HorseSelectFormControl);
Vue.component('a-html-form-control', HtmlFormControl);
Vue.component('a-illness-form-control', IllnessFormControl);
Vue.component('a-interval-form-control', IntervalFormControl);
Vue.component('a-intervals-form-control', IntervalsFormControl);
Vue.component('a-image-cropper-form-control', ImageCopperFormControl);
Vue.component('a-language-form-control', LanguageFormControl);
Vue.component('a-link-form-control', LinkFormControl);
Vue.component('a-locale-form-control', LocaleFormControl);
Vue.component('a-month-form-control', MonthFormControl);
Vue.component('a-moment-date-picker-form-control', MomentDatePickerFormControl);
Vue.component('a-move-horse-to-form-control', MoveHorseToFormControl);
Vue.component('a-multi-text-form-control', MultiTextFormControl);
Vue.component('a-name-form-control', NameFormControl);
Vue.component('a-name-order-type-form-control', NameOrderTypeFormControl);
Vue.component('a-number-form-control', NumberFormControl);
Vue.component('a-number-autocomplete-form-control', NumberAutocompleteFormControl);
Vue.component('a-number-range-interval-form-control', NumberRangeIntervalFormControl);
Vue.component('a-number-selection-form-control', NumberSelectionFormControl);
Vue.component('a-option-form-control', OptionFormControl);
Vue.component('a-options-form-control', OptionsFormControl);
Vue.component('a-organization-autocomplete-form-control', OrganizationAutocompleteFormControl);
Vue.component('a-password-form-control', PasswordFormControl);
Vue.component('a-payment-method-form-control', PaymentMethodFormControl);
Vue.component('a-persona-form-control', PersonaFormControl);
Vue.component('a-person-autocomplete-form-control', PersonAutocompleteFormControl);
Vue.component('a-person-multi-autocomplete-form-control', PersonMultiAutocompleteFormControl);
Vue.component('a-phone-number-form-control', PhoneNumberFormControl);
Vue.component('a-address-form-control', AddressFormControl);
Vue.component('a-strain-form-control', StrainFormControl);
Vue.component('a-subscription-schedule-form-control', SubscriptionScheduleFormControl);
Vue.component('a-textarea-form-control', TextareaFormControl);
Vue.component('a-text-form-control', TextFormControl);
Vue.component('a-time-configuration-type-form-control', TimeConfigurationTypeFormControl);
Vue.component('a-time-form-control', TimeFormControl);
Vue.component('a-time-frame-form-control', TimeFrameFormControl);
Vue.component('a-time-frame-interval-form-control', TimeFrameIntervalFormControl);
Vue.component('a-time-frame-select-form-control', TimeFrameSelectFormControl);
Vue.component('a-time-option-interval-form-control', TimeOptionIntervalFormControl);
Vue.component('a-time-option-interval-selection-form-control', TimeOptionIntervalSelectionFormControl);
Vue.component('a-registration-code-form-control', RegistrationCodeFormControl);
Vue.component('a-reservation-display-name-type-form-control', ReservationDisplayNameTypeFormControl);
Vue.component('a-riding-lesson-autocomplete-form-control', RidingLessonAutocompleteFormControl);
Vue.component('a-riding-lesson-duration-form-control', RidingLessonDurationFormControl);
Vue.component('a-riding-lesson-multi-checkbox-form-control', RidingLessonMultiCheckboxFormControl);
Vue.component('a-riding-lesson-registration-type-radio-form-control', RidingLessonRegistrationTypeRadioFormControl);
Vue.component('a-riding-lesson-type-autocomplete-form-control', RidingLessonTypeAutocompleteFormControl);
Vue.component('a-salutation-form-control', SalutationFormControl);
Vue.component('a-survey-answer-per-form-control', SurveyAnswerPerFormControl);
Vue.component('a-user-autocomplete-form-control', UserAutocompleteFormControl);
Vue.component('a-user-group-autocomplete-form-control', UserGroupAutocompleteFormControl);
Vue.component('a-user-group-multi-form-control', UserGroupMultiFormControl);
Vue.component('a-user-multi-select-form-control', UserMultiSelectFormControl);
Vue.component('a-user-multi-checkbox-form-control', UserMultiCheckboxFormControl);
Vue.component('a-user-role-form-control', UserRoleFormControl);
Vue.component('a-vaccination-wait-period-form-control', VaccinationWaitPeriodFormControl);
Vue.component('a-vaccine-form-control', VaccineFormControl);
Vue.component('a-weekday-form-control', WeekdayFormControl);
Vue.component('a-weekdays-form-control', WeekdaysFormControl);
Vue.component('a-yes-no-select-form-control', YesNoSelectFormControl);

// -- Domain form controls
Vue.component('a-feeding-settings-form-control', FeedingSettingsFormControl);
Vue.component('a-gerblhof-concentrated-feeding-amount-form-control', GerblhofConcentratedFeedingAmountFormControl);
Vue.component('a-gerblhof-concentrated-feedings-form-control', GerblhofConcentratedFeedingsFormControl);
Vue.component('a-gerblhof-concentrated-feeding-type-form-control', GerblhofConcentratedFeedingTypeSelectFormControl);
Vue.component('a-survey-answers-for-horses-form-control', SurveyAnswersForHorsesFormControl);
Vue.component('a-survey-answers-for-horses-multiple-form-control', SurveyAnswersForHorsesMultipleFormControl);
Vue.component('a-survey-answers-for-user-form-control', SurveyAnswersForUserFormControl);
Vue.component('a-survey-answers-for-user-multiple-form-control', SurveyAnswersForUserMultipleFormControl);
Vue.component('a-riding-lesson-registration-radio-form-control', RidingLessonRegistrationRadioFormControl);
