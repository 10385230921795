
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { moment } from '@/helpers';
import { useLaborServicesManagementStore } from '../store';

interface Controls extends FormControls {
  summaryYear: FormControl<number>;
}

@Component
export default class ChangeSummaryYearDialog extends Vue {

  readonly store = useLaborServicesManagementStore();

  readonly dialogMaxWidth = DialogWidth.small;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get currentYear(): number {
    return moment().year();
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        summaryYear: {
          label: 'Jahr für die Zusammenfassung',
          value: this.store.summaryYear,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    this.store.updateSummaryYear(formValues.summaryYear!)
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
