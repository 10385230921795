
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, isFieldShownAsContainingAnError, labelWithRequiredIndicator, mountFormControl, wasValidationSuccessful } from '@/components/form';
import { Language } from '@/types';
import { VuetifySelectItem } from '@/application/types';
import { languageTranslation } from '@/helpers/translations';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class LanguageFormControl extends Vue implements FormControlComponent<Language> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Language>;

  @Prop({ type: Array, default: () => [] })
  readonly hiddenLanguages!: Language[];

  @Prop({ type: Boolean, default: false })
  readonly isAutofocused!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  readonly formControlId = createFormControlId();

  get items(): VuetifySelectItem<Language>[] {
    return Object.values(Language)
      .filter((language) => !this.hiddenLanguages.includes(language))
      .map((language) => ({
        text: languageTranslation[language],
        value: language,
      }));
  }

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  internalValue: Language | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  mounted(): void {
    mountFormControl(this);
  }

  selectionChanged(): void {
    internalValuesChanged(this);
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalValue = this.formControl.value === null
      ? this.isClearable
        ? null
        : this.items[0].value
      : this.formControl.value;
  }

  formValueFromInternalValues(): FormControlValue<Language> {
    return this.internalValue;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
