
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { constructForm, csvCompliantRule, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { FarmServiceName, FarmServiceNameTranslations, Language, languagesWithoutGerman, LanguageWithoutGerman } from '@/types';

interface Controls extends FormControls {
  language: FormControl<LanguageWithoutGerman>;
  name: FormControl<FarmServiceName>;
}

@Component
export default class CreateFarmServiceNameTranslationDialog extends Vue {

  readonly dialogMaxWidth = DialogWidth.smallNested;

  @Prop({ type: Object, required: true })
  readonly farmServiceNameTranslations!: FarmServiceNameTranslations;

  @Prop({ type: Boolean, required: true })
  readonly isDisabled!: boolean;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  hiddenLanguages(): LanguageWithoutGerman[] {
    const languagesWithTranslations = Object.keys(this.farmServiceNameTranslations);
    languagesWithTranslations.push(Language.de);

    return languagesWithTranslations as LanguageWithoutGerman[];
  }

  buildForm(): Form<Controls> {
    const languagesLeft = languagesWithoutGerman
      .filter((language) => !this.hiddenLanguages().includes(language as LanguageWithoutGerman));

    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        language: {
          label: 'Sprache',
          value: languagesLeft[0],
          isRequired: true,
          rules: [],
        },
        name: {
          label: 'Name',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
            csvCompliantRule(),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const nameTranslation: { language: LanguageWithoutGerman, name: FarmServiceName } = {
      language: formValues.language!,
      name: formValues.name!,
    };

    this.$emit('submitted', nameTranslation);

    this.closeDialog();
  }

  closeDialog(): void {
    this.isDialogVisible = false;
    this.$emit('closed');
  }

}
