
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useLaborServicesManagementStore } from '../store';
import { TimeEntry, MarkTimeEntryAsRejectedAsManagerCommand } from '../types';

interface Controls extends FormControls {
  rejectReason: FormControl<string>;
}

@Component
export default class RejectTimeEntryDialog extends Vue {

  readonly store = useLaborServicesManagementStore();

  @Prop({ type: Object, required: true })
  readonly timeEntry!: TimeEntry;

  readonly dialogMaxWidth = DialogWidth.small;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        rejectReason: {
          label: 'Grund für Ablehnung',
          value: null,
          rules: [],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: MarkTimeEntryAsRejectedAsManagerCommand = {
      laborServiceTimeEntryId: this.timeEntry.laborServiceTimeEntryId,
      rejectReason: formValues.rejectReason,
    };

    this.store.markTimeEntryAsRejected(command)
      .then(() => showSuccessMessage('Der verrichtete Arbeitsdienst wurde abgelehnt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
