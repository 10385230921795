import range from 'lodash-es/range';
import { formatNumber } from '@/helpers/stateful-format';

export function requiredRule(message = 'Dieses Feld muss gefüllt sein'): (value: any) => true|string {
  return (value) => (
    // As soon as all input elements are refactored, we can simply check for value !== null and remove the rest
    value !== null
    && (
      typeof value === 'number'
      || (
        typeof value !== 'string'
        && !!value
      )
      || (
        typeof value === 'string'
        && !!value.trim()
      )
    )
  ) || message;
}

export function positiveNumberRule(decimalPlaces = 0, customMessage?: string): (value: any) => true|string {
  let regex = /^\d+$/;
  let message = customMessage && customMessage || 'Die Wert darf keine Nachkommastellen enthalten';

  if (decimalPlaces > 0) {
    // eslint-disable-next-line prefer-regex-literals
    regex = new RegExp(`^\\d+(\\.\\d{${range(1, decimalPlaces + 1).join(',')}})?$`);
    message = customMessage && customMessage || `Die Wert darf maximal ${decimalPlaces} Nachkommastellen enthalten`;
  }

  return (value) => (!value || regex.test(parseFloat(value).toString())) || message;
}

export function minNumberRule(min: number, customMessage?: string): (value: number|null) => true|string {
  const message = customMessage || `Die Zahl darf nicht kleiner als ${formatNumber(min)} sein`;
  return (value) => (value === null || value >= min) || message;
}

export function maxLengthRule(max: number, customMessage?: string): (value: any) => true|string {
  const message = customMessage && customMessage || `Der Wert darf aus maximal ${max} Zeichen bestehen`;
  return (value) => (!value || value.length <= max) || message;
}

export function notEmptyArrayRule(message = 'Es muss mindestens ein Element ausgewählt sein'): (value: any) => true|string {
  return (value) => (!value || Array.isArray(value) && value.length > 0) || message;
}
