
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useLaborServicesManagementStore } from '../store';
import { TimeEntry } from '../types';
import { sortUser } from '../helper';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component
export default class HandledTimeEntries extends Vue {

  readonly store = useLaborServicesManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    {
      text: 'Benutzer',
      value: 'user',
      sortable: true,
      sort: sortUser,
    }, {
      text: 'Datum',
      value: 'date',
      sortable: true,
    }, {
      text: 'Stundenanzahl',
      value: 'numberOfHours',
      sortable: false,
    }, {
      text: 'Beschreibung',
      value: 'description',
      sortable: false,
    }, {
      text: 'Bearbeiter',
      value: 'handledBy',
      sortable: false,
    }, {
      text: 'Status',
      align: 'center',
      value: 'status',
      sortable: false,
    },
  ];

  form: Form<Controls> | null = null;

  get searchValue(): string | null {
    if (!this.form) {
      return '';
    }

    const formValues = getFormValues(this.form);

    return formValues.search;
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getTimeRecordings()
     .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
          rules: [],
        },
      },
    });
  }

  filterTimeEntries(value: any, search: string | null, timeEntry: TimeEntry): boolean {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (timeEntry.user.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (timeEntry.description && timeEntry.description.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (timeEntry.handledBy && timeEntry.handledBy.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    return false;
  }

}
