
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { createFormControlId, emptyFormFieldWatcher, errorMessagesForFormControl, FormControl, FormControlComponent, FormControlValue, FormFunctions, internalValuesChanged, mountFormControl, wasValidationSuccessful, isFieldShownAsContainingAnError, labelWithRequiredIndicator } from '@/components/form';
import { VuetifySelectItem } from '@/application/types';
import { Moment, Time, TimeFrame } from '@/types';
import { generateTimesSelectItems } from '@/helpers/form-helpers';
import { formattedDatePickerValueAsDate, moment } from '@/helpers';

@Component({
  methods: { isFieldShownAsContainingAnError, labelWithRequiredIndicator },
})
export default class DateTimePickerFormControl extends Vue implements FormControlComponent<Moment> {

  @Inject('formFunctions')
  readonly formFunctions!: FormFunctions;

  @Prop({ type: Object, required: true })
  readonly formControl!: FormControl<Moment>;

  @Prop({ type: Function, default: null })
  readonly isDateAllowed!: ((date: Moment) => boolean) | null;

  @Prop({ type: Object, default: null })
  readonly timeFrame!: TimeFrame | null;

  @Prop({ type: Number, default: 15 })
  readonly intervalMinutes!: number;

  @Prop({ type: Boolean, default: false })
  readonly isClearable!: boolean;

  readonly formControlId = createFormControlId();

  isFocused = false;
  isTouched = false;
  isMarkedAsMessagesForcedVisible = false;

  messages: string[] = [];

  isMenuVisible = false;

  internalDateValue = '';
  internalTimeValue: Time | null = null;

  formFieldValueWatcher = emptyFormFieldWatcher();

  get formattedTextFieldValue(): string {
    return formattedDatePickerValueAsDate(this.internalDateValue);
  }

  get timesSelectItems(): VuetifySelectItem<Time>[] {
    const relevantTimeFrame = this.timeFrame !== null
      ? this.timeFrame
      : {
        timeFrom: new Time(5, 0, 0),
        timeTo: new Time(0, 0, 0),
      };

    return generateTimesSelectItems(relevantTimeFrame.timeFrom, relevantTimeFrame.timeTo, this.intervalMinutes);
  }

  mounted(): void {
    mountFormControl(this);
  }

  // Value is set to null on clear and on reset (although I'm not sure why on reset)
  dateChanged(): void {
    if (this.internalDateValue === null) {
      this.internalDateValue = '';
    }

    internalValuesChanged(this);

    this.isMenuVisible = false;
  }

  timeChanged(): void {
    internalValuesChanged(this);
  }

  allowedDates(date: string): boolean {
    return this.isDateAllowed !== null
      ? this.isDateAllowed(moment(date))
      : true;
  }

  focused(): void {
    this.isFocused = true;
  }

  blurred(): void {
    this.isFocused = false;
    this.isTouched = true;
  }

  // -- Form control functions

  validateFormValue(): boolean {
    this.messages = [
      ...errorMessagesForFormControl(this.formControl),
    ];

    return wasValidationSuccessful(this.messages);
  }

  updateInternalValues(): void {
    this.internalDateValue = this.formControl.value === null
      ? ''
      : this.formControl.value.format('YYYY-MM-DD');
    this.internalTimeValue = this.formControl.value === null
      ? null
      : Time.fromMoment(this.formControl.value);
  }

  formValueFromInternalValues(): FormControlValue<Moment> {
    return this.internalDateValue.trim().length > 0
      && this.internalTimeValue !== null
      ? moment(this.internalDateValue)
        .startOf('day')
        .hour(this.internalTimeValue.hour)
        .minute(this.internalTimeValue.minute)
        .second(this.internalTimeValue.second)
      : null;
  }

  forceMessagesVisible(): void {
    this.isMarkedAsMessagesForcedVisible = true;
  }

}
