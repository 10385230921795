
import { Component, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { UserGroupId } from '@/types';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxDecimalsNumberRule, minNumberRule } from '@/components/form';
import { useLaborServicesManagementStore } from '../store';
import { CreateSettingsByUserGroupAsManagerCommand } from '../types';

interface Controls extends FormControls {
  userGroup: FormControl<UserGroupId>;
  annualNumberOfHours: FormControl<number>;
}

@Component
export default class CreateSettingsByUserGroupDialog extends Vue {

  readonly store = useLaborServicesManagementStore();

  readonly size = DialogWidth.small;

  isDialogVisible = false;

  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get hasAvailableUserGroups(): boolean {
    return this.store.availableUserGroups.length > 0;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      if (isDialogVisible) {
        this.store.getAvailableUserGroups()
          .then(() => {
            this.form = this.buildForm();
          })
          .catch((error) => showErrorResponse(error));
      } else {
        this.form = null;
      }
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        userGroup: {
          label: 'Gruppe',
          value: '',
          isRequired: true,
        },
        annualNumberOfHours: {
          label: 'Jährliche Stundenanzahl pro Reiter',
          value: null,
          isRequired: true,
          rules: [
            maxDecimalsNumberRule(2),
            minNumberRule(0),
          ],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateSettingsByUserGroupAsManagerCommand = {
      userGroupId: formValues.userGroup!,
      annualNumberOfHours: formValues.annualNumberOfHours!,
    };

    this.store.createSettingsByUserGroup(command)
      .then(() => showSuccessMessage('Die Konfiguration wurde angelegt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
