
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useLedgerStore } from '../store';

@Component
export default class LedgerAdminComponent extends Vue {

  readonly store = useLedgerStore();

  mounted(): void {
    this.store.getPersons()
      .catch((error) => showErrorResponse(error));
  }

}
