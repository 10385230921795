
import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { showErrorResponse } from '@/application/snackbar/service';
import { isMobileSafari, isNativeApplication } from '@/helpers/detection-helpers';
import { downloadCSVFile } from '@/helpers/file-download-helper';
import { moment } from '@/helpers';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useLaborServicesManagementStore } from '../store';
import { TimeEntry } from '../types';
import ChangeSummaryYearDialog from './change-summary-year-dialog.vue';

interface Controls extends FormControls {
  search: FormControl<string>;
}

@Component({
  components: {
    ChangeSummaryYearDialog,
  },
})
export default class LaborServiceSummary extends Vue {

  readonly store = useLaborServicesManagementStore();

  readonly tableHeaders: DataTableHeader[] = [
    { text: 'Benutzer', value: 'user' },
    { text: 'Stundenanzahl', value: 'numberOfHoursSummary' },
  ];

  readonly isMobileSafari = isMobileSafari;
  readonly isNativeApplication = isNativeApplication;

  form: Form<Controls> | null = null;

  get searchValue(): string | null {
    if (!this.form) {
      return '';
    }

    const formValues = getFormValues(this.form);

    return formValues.search;
  }

  mounted(): void {
    this.form = this.buildForm();

    this.store.getTimeRecordings()
      .catch((error) => showErrorResponse(error));
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: () => {},
      controls: {
        search: {
          label: 'Suche',
          value: null,
          rules: [],
        },
      },
    });
  }

  filterTimeEntries(value: any, search: string | null, timeEntry: TimeEntry) {
    if (search === null) {
      return true;
    }

    const searchLowerCase = search.toLocaleLowerCase();

    if (timeEntry.user.name.toLocaleLowerCase().includes(searchLowerCase)) {
      return true;
    }

    if (timeEntry.description
      && timeEntry.description.toLocaleLowerCase().includes(searchLowerCase)
    ) {
      return true;
    }

    if (timeEntry.handledBy
      && timeEntry.handledBy.name.toLocaleLowerCase().includes(searchLowerCase)
    ) {
      return true;
    }

    return false;
  }

  exportSummaryEntriesAsCsvClicked(): void {
    const fileName = `Zusammenfassung-${this.store.summaryYear}-Arbeitsdienst-${moment().format('YYYY-MM-DD')}.csv`;
    this.store.exportSummaryEntriesAsCsv()
      .then((csv) => downloadCSVFile(csv, fileName))
      .catch((error) => showErrorResponse(error));
  }

}
