
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { constructForm, Form, FormControl, FormControls, getFormValues, ValuesOfFormControls } from '@/components/form';
import { DialogWidth } from '@/helpers/data';
import { Moment, RidingLessonDuration, Time, TimeFrame } from '@/types';
import { moment } from '@/helpers';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { midnightSevenDaysAgo } from '../helper';
import { useRidingLessonManagementStore } from '../store';
import { Facility, RidingLesson, UpdateTimeFrameOfRidingLessonAsManagerCommand } from '../types';

interface Controls extends FormControls {
  from: FormControl<Moment>;
  duration: FormControl<RidingLessonDuration>;
  to: FormControl<Time>;
  lastRegistrationAt: FormControl<Moment>;
}

@Component
export default class UpdateTimeFrameOfRidingLessonDialog extends Vue {

  readonly store = useRidingLessonManagementStore();

  @Prop({ type: Object, required: true })
  readonly ridingLesson!: RidingLesson;

  readonly dialogMaxWidth = DialogWidth.large;

  form: Form<Controls> | null = null;
  isDialogVisible = false;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get facility(): Facility | null {
    return this.store.facilities
      .find((facility) => facility.facilityId === this.ridingLesson.facility.facilityId) ?? null;
  }

  get timeFrameForFrom(): TimeFrame | null {
    return this.facility === null
      ? null
      : this.facility.openingHours;
  }

  get isLastRegistrationInThePast(): boolean {
    if (!this.form) {
      return false;
    }

    const formValues = getFormValues(this.form);

    return !!formValues.lastRegistrationAt
      && formValues.lastRegistrationAt.isBefore(moment());
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    const form = constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        from: {
          label: 'Beginn',
          value: this.ridingLesson.from,
          isRequired: true,
          rules: [
            this.dateTimeAfterMidnight7DaysAgoRule(),
          ],
          transformValuesOnInput: (values: ValuesOfFormControls<FormControls>): Partial<ValuesOfFormControls<FormControls>> => {
            const transformedValues: Partial<ValuesOfFormControls<FormControls>> = {};

            transformedValues.to = Time.fromMoment(
              values.from!
                .add(values.duration!, 'minutes')
            );
            transformedValues.lastRegistrationAt = values.from;

            return transformedValues;
          },
          validateFormControlsOnInput: [
            'lastRegistrationAt',
          ],
        },
        duration: {
          label: 'Dauer',
          value: this.ridingLesson.duration,
          isRequired: true,
          transformValuesOnInput: (values: ValuesOfFormControls<Controls>): Partial<ValuesOfFormControls<Controls>> => {
            const transformedValues: Partial<ValuesOfFormControls<Controls>> = {};
            if (this.form!.controls.from.value) {
              transformedValues.to = Time.fromMoment(
                values.from!
                  .add(values.duration!, 'minutes')
              );
            }

            return transformedValues;
          }
        },
        to: {
          label: 'Bis',
          value: Time.fromMoment(this.ridingLesson.to),
          rules: [],
        },
        lastRegistrationAt: {
          label: 'Anmeldeschluss',
          value: this.ridingLesson.lastRegistrationAt,
          isRequired: true,
          rules: [
            this.dateTimeAfterMidnight7DaysAgoRule(),
          ],
        },
      },
    });

    form.controls.lastRegistrationAt.rules!.push(this.dateTimeBeforeRule(form.controls.from));

    return form;
  }

  dateTimeAfterMidnight7DaysAgoRule(): (value: Moment|null) => true|string {
    return (value: Moment|null) => value === null
      || value.isAfter(
        moment()
          .startOf('day')
          .subtract(7, 'days')
      )
      || 'Der Zeitpunkt darf maximal 7 Tage in der Vergangenheit liegen';
  }

  dateTimeBeforeRule(formControl: FormControl<Moment>): (value: Moment|null) => true|string {
    return (value: Moment|null) => value === null
      || formControl.value === null
      || value.isSameOrBefore(formControl.value)
      || 'Der Anmeldeschluss darf nicht nach dem Beginn der Unterrichtsstunde liegen';
  }

  isFromAllowed(date: Moment): boolean {
    return date.isSameOrAfter(midnightSevenDaysAgo());
  }

  isLastRegistrationAtAllowed(date: Moment): boolean {
    const dayOfRidingLesson = this.form!.controls.from.value!.startOf('day');

    return date.isSameOrAfter(midnightSevenDaysAgo(), 'day')
      && date.isSameOrBefore(dayOfRidingLesson, 'day');
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateTimeFrameOfRidingLessonAsManagerCommand = {
      ridingLessonId: this.ridingLesson.ridingLessonId,
      from: formValues.from!,
      duration: formValues.duration!,
      to: formValues.from!
        .add(formValues.duration!, 'minutes'),
      lastRegistrationAt: formValues.lastRegistrationAt!,
    };

    this.store.updateTimeFrameOfRidingLesson(command)
      .then(() => showSuccessMessage('Zeitfenster wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
