
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse } from '@/application/snackbar/service';
import { useLedgerStore } from '../store';
import GerblhofFeeding from './gerblhof-feeding.vue';
import ConfigurationForCustomBookingsAndCreditNotes from './configuration-for-custom-bookings-and-credit-notes.vue';

@Component({
  components: {
    GerblhofFeeding,
    ConfigurationForCustomBookingsAndCreditNotes,
  },
})
export default class LedgerConfiguration extends Vue {

  readonly store = useLedgerStore();

  mounted(): void {
    this.store.getConfiguration()
      .catch((error) => showErrorResponse(error));
  }

}
