
import { Component, Prop, Vue } from 'vue-property-decorator';
import { watch } from 'vue';
import { showErrorResponse, showSuccessMessage } from '@/application/snackbar/service';
import { DialogWidth } from '@/helpers/data';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { UpdateVaccinationNoticeCommand, Vaccination } from '../types';
import { useMyStableStore } from '../store';

interface Controls extends FormControls {
  notice: FormControl<string>;
}

@Component
export default class UpdateVaccinationNoticeDialog extends Vue {

  readonly store = useMyStableStore();

  readonly dialogMaxWidth = DialogWidth.large;

  @Prop({ type: Object, required: true })
  readonly vaccination!: Vaccination;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  get isDialogDisabled(): boolean {
    return this.store.vaccinationCertificate === null;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, (isDialogVisible) => {
      this.form = isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm<Controls>({
      submitted: this.submitted,
      controls: {
        notice: {
          label: 'Notiz',
          value: this.vaccination.notice,
          rules: [],
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateVaccinationNoticeCommand = {
      vaccinationId: this.vaccination.vaccinationId,
      notice: formValues.notice,
    };

    this.store.updateVaccinationNotice(command)
      .then(() => showSuccessMessage('Notiz wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }
}
