import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature, UserRole, FarmManagerPermission } from '@/types';
import LedgerConfigurationTabs from './ledger-configuration/components/ledger-configuration-tabs.vue';
import SettlementsForMonth from './ledger-configuration/components/settlements-for-month.vue';
import CustomBookings from './ledger-configuration/components/custom-bookings.vue';
import CreditNotes from './ledger-configuration/components/credit-notes.vue';
import LedgerConfiguration from './ledger-configuration/components/ledger-configuration.vue';
import PaymentReceipts from './payment-receipt/components/payment-receipts.vue';
import SettlementForecastTable from './settlement-forecast/components/settlement-forecast-table.vue';

export const ledgerConfigurationRoutes: RouteConfig[] = [
  {
    path: 'abrechnung',
    component: LedgerConfigurationTabs,
    children: [
      {
        name: 'admin-ledger',
        path: '',
        redirect: 'sonderkosten',
      },
      {
        name: 'admin-ledger-custom-bookings',
        path: 'sonderkosten',
        component: CustomBookings,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'admin-ledger-credit-notes',
        path: 'gutschriften',
        component: CreditNotes,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'admin-ledger-settlement',
        path: 'abrechnung',
        component: SettlementsForMonth,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'ledger-payment-receipt',
        path: 'zahlungseingang',
        component: PaymentReceipts,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'ledger-settlement-forecast',
        path: 'prognose',
        component: SettlementForecastTable,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
      {
        name: 'admin-ledger-configuration',
        path: 'konfiguration',
        component: LedgerConfiguration,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresRole: UserRole.ROLE_FARM_MANAGER,
          requiresFeature: Feature.LEDGER,
          requiresPermission: FarmManagerPermission.LEDGER_READ,
        },
      },
    ],
  },
];
